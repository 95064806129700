import { render, staticRenderFns } from "./ChangeCardBalanceForm.vue?vue&type=template&id=25562409&"
import script from "./ChangeCardBalanceForm.ts?vue&type=script&lang=ts&"
export * from "./ChangeCardBalanceForm.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports