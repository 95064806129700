import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import API from "@api";
import debounce from "lodash.debounce";
import {
  isEmpty,
  isPositiveNumber,
  getSymbolCurrency,
  formatMoney,
  numberToFixed,
} from "@helpers";
import { WalletActions } from "@store/modules/wallet/types";
import { walletModule } from "@store/namespaces";
import { Currency } from "@/types/currency";
import { VForm } from "@/types/vuetify";

const MIN_TRANSFER_AMOUNT = 10;
const MIN_CARD_BALANCE_AMOUNT = 50;

@Component
export default class ChangeCardBalanceForm extends Vue {
  @walletModule.Action("fetchWallets")
  private readonly fetchWalletsAction!: WalletActions["fetchWallets"];

  @Ref("form") private readonly formRef?: VForm;
  @Prop({ type: String, required: true }) private readonly sender!: string;
  @Prop({ type: Number, required: true })
  private readonly senderBalance!: number;
  @Prop({ type: [String, Number], required: true })
  private readonly senderId!: number | string;
  @Prop({ type: String, required: true }) private readonly recipient!: string;
  @Prop({ type: Number, required: true })
  private readonly recipientBalance!: number;
  @Prop({ type: [String, Number], required: true })
  private readonly recipientId!: number | string;
  @Prop({ type: String, required: true }) private readonly currency!: Currency;
  @Prop({ type: Number, required: true }) private readonly cardId!: number;

  private validatedForm = false;
  private amount = "";
  private loading = false;
  private localSender: {
    id: string | number;
    value: string;
  } = {
    id: "",
    value: "",
  };
  private localRecipient: {
    id: string | number;
    value: string;
  } = {
    id: "",
    value: "",
  };

  private get fieldRules() {
    return {
      required: (v: string) =>
        !isEmpty(v) || this.$vuetify.lang.t("$vuetify.errors.required"),
      positiveNumber: (v: string) =>
        isPositiveNumber(v) ||
        this.$vuetify.lang.t("$vuetify.errors.positive_number"),
      amount: (v: string) => {
        const errorMessage = [
          this.fieldRules.required(v),
          this.fieldRules.positiveNumber(v),
        ].find((value) => typeof value === "string");

        if (errorMessage) {
          return errorMessage;
        }

        const amountVal = numberToFixed(v);

        if (amountVal < MIN_TRANSFER_AMOUNT) {
          return this.$vuetify.lang.t(
            "$vuetify.errors.min_amount_transfer",
            formatMoney({
              value: MIN_TRANSFER_AMOUNT,
              currency: this.currency,
            })
          );
        }

        if (this.withDrawCard) {
          const balanceCardAfterTransfer = this.currentBalance - amountVal;

          if (balanceCardAfterTransfer < MIN_CARD_BALANCE_AMOUNT) {
            return this.$vuetify.lang.t(
              "$vuetify.errors.minimal_card_balance_cannot_be_less",
              formatMoney({
                value: MIN_CARD_BALANCE_AMOUNT,
                currency: this.currency,
              })
            );
          }
        } else if (
          this.currentBalance <= 0 ||
          this.currentBalance < amountVal
        ) {
          return this.$vuetify.lang.t("$vuetify.errors.insufficient_balance");
        }

        return true;
      },
    };
  }

  private get title() {
    if (this.withDrawCard) {
      return this.$vuetify.lang.t("$vuetify.dashboard.transfer_to_balance");
    }

    return this.$vuetify.lang.t("$vuetify.dashboard.card_deposit");
  }

  private get currencyIcon() {
    return require(`@/assets/img/currency/flags/${this.currency}.svg`);
  }

  private get currencySymbol() {
    return getSymbolCurrency(this.currency);
  }

  private get currentBalance() {
    return this.localSender.id === this.recipientId
      ? this.recipientBalance
      : this.senderBalance;
  }

  private get formatedSender() {
    if (this.senderId === this.localSender.id) {
      return this.$vuetify.lang.t("$vuetify.dashboard.my_balance");
    }

    return this.localSender.value;
  }

  private get formatedRecipient() {
    if (this.recipientId === this.localSender.id) {
      return this.$vuetify.lang.t("$vuetify.dashboard.my_balance");
    }

    return this.localRecipient.value;
  }

  private get withDrawCard() {
    return this.cardId === this.localSender.id;
  }

  private reverseSenderAndRecipient() {
    const sender = this.localSender;
    const recipient = this.localRecipient;

    this.localSender = recipient;
    this.localRecipient = sender;
  }

  private onClickClose() {
    this.$emit("close");
  }

  private validateForm() {
    return !!this.formRef?.validate();
  }

  private onSubmitForm() {
    if (!this.validateForm()) return;

    this.transferFundsWallet();
  }

  private async transferFundsWallet() {
    if (this.loading) return;

    this.loading = true;

    try {
      if (this.withDrawCard) {
        await API.card.withDrawCard({
          cardId: this.cardId,
          amount: +this.amount,
        });
      } else {
        await API.card.depositCard({
          cardId: this.cardId,
          amount: +this.amount,
        });
      }

      this.$emit("success");
      this.fetchWalletsAction();
    } finally {
      this.loading = false;
    }
  }

  private created() {
    this.$watch(
      () => {
        return this.recipient;
      },
      (value) => {
        this.localRecipient.value = value;
      },
      {
        immediate: true,
      }
    );

    this.$watch(
      () => {
        return this.recipientId;
      },
      (id) => {
        this.localRecipient.id = id;
      },
      {
        immediate: true,
      }
    );

    this.$watch(
      () => {
        return this.sender;
      },
      (value) => {
        this.localSender.value = value;
      },
      {
        immediate: true,
      }
    );

    this.$watch(
      () => {
        return this.senderId;
      },
      (id) => {
        this.localSender.id = id;
      },
      {
        immediate: true,
      }
    );

    this.$watch(
      () => {
        return [this.amount, this.withDrawCard].join("-");
      },
      debounce(
        () => {
          this.validatedForm = this.fieldRules.amount(this.amount) === true;
        },
        400,
        {
          maxWait: 400,
          leading: true,
          trailing: true,
        }
      ),
      {
        immediate: true,
      }
    );

    this.$watch(
      () => {
        return this.withDrawCard;
      },
      () => {
        this.validateForm();
      }
    );

    this.$watch(
      () => {
        return this.loading;
      },
      (loading) => {
        this.$emit("update:loading", loading);
      }
    );
  }
}
